.large-info h1 {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    color: #1f2937;
    padding-top: 2rem;
    margin-bottom: 1.5rem;
}

.large-info h2 {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.25rem;
    letter-spacing: -0.02em;
    color: #1f2937;
    padding-top: 0.5rem;
    margin-bottom: 1rem;

}

.large-info p {
    font-size: 1rem;
    font-weight: 500;
    color: #4b5563;
    margin-bottom: 1rem;
}

.large-info ul {
    margin-bottom: 2rem;
    list-style-type: disc;
    text-align: left;
    gap: 1rem;
}

.large-info ul li {
    gap: 0.75rem;
    font-weight: 500;
    color: #4b5563;
    margin-left: 2rem;
}
