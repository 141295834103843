@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../Images/background.png"); /* Replace with your image path */
	background-size: cover; /* Ensures the image covers the entire body */
	background-position: center center; /* Centers the image */
	background-repeat: no-repeat; /* Prevents the image from repeating */
	min-height: 100vh; /* Ensures the body has at least the height of the viewport */
	margin: 0; /* Removes default margin */
	padding: 0;
	position: relative; /* Ensure positioning c  context for pseudo-elements */
	backdrop-filter: blur(5px);
}

/* React Paginate */

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	list-style: none;
	padding: 0;
	margin-top: 2em;
	padding-top: 2em;
}

/* Style for each pagination link */
.pagination a {
	padding: 10px 15px;
	margin: 0 5px;
	text-decoration: none;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
}

/* Style for the active page link */
.pagination .active a {
	background-color: #3498db;
	color: white;
	border-color: #3498db;
}

/* Style for previous/next links */
.pagination .previous a,
.pagination .next a {
	background-color: #f0f0f0;
	color: #333;
}

/* Hover effect for links */
.pagination a:hover {
	background-color: #2980b9;
	color: white;
}

.video-responsive {
    width: 80%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (9/16 = 0.5625) */
    position: relative;
    height: 0;
    margin: 0 auto; /* Centers the container horizontally */
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }